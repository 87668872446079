import * as React from "react"
import { Box, BoxProps } from "@chakra-ui/react"

interface Props extends Omit<BoxProps, "content"> {
  content: string | null | undefined
}

export function SanitizedContent({ content, ...props }: Props) {
  // React.useEffect(() => {
  //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   // @ts-ignore
  //   instgrm.Embeds.process()
  // }, [props.content])

  return (
    <Box
      dangerouslySetInnerHTML={{
        __html: content || "",
      }}
      className="sanitized"
      w="100%"
      sx={{ 
        strong: { fontWeight: 900 },
        wordBreak: "break-word",
        overflowWrap: "break-word"
      }}
      {...props}
    />
  )
}
